<template>
  <div class="form-toggle">
    <span
      v-if="field.label && isLabelBeforeToggle"
      v-html="field.label"
      class="form-toggle__title"
      :class="{'form-toggle__title--left': isLabelBeforeToggle}"
    ></span>
    <label class="switch">
      <input
        @change="$emit('input', $event.target.checked)"
        :checked="value"
        type="checkbox"
      >
      <span class="slider round "></span>
    </label>
    <span
      v-if="field.label && !isLabelBeforeToggle"
      v-html="field.label"
      class="form-toggle__title"
    ></span>
  </div>
</template>

<script>
import FormControlMixin from '@/mixins/FormControlMixin'

export default {
  name: 'FormToggle',
  mixins: [FormControlMixin],
  props: {
    isLabelBeforeToggle: {
      type: Boolean,
      default: false
    }
  }
}
</script>
