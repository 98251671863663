<template>
  <div
    class="add-to-products-list"
    :class="{
      'add-to-products-list--dropdown-opened': showListDropdown,
      'add-to-products-list--visible': isProductInFavorites,
      'add-to-products-list--active': showCreateNewProductsListModal || showAddedProductModal
    }"
  >
    <div class="add-to-products-list__inner" v-click-outside="() => toggleDropdown(false)">
      <h3
        class="text-h4 add-to-products-list__button"
        @click="onLabelClick"
        v-tooltip="{
          content: isGuest ? 'Create an account or log in to save items to a list' : '',
          trigger: 'hover'
        }"
      >
        <FavoritesIcon
          v-if="isFavoritesIconMode"
          :disabled="true"
          :productCode="productData.sku"
          class="product__favorites"
        />
        <span v-else>Add to List</span>
      </h3>
      <div v-if="showListDropdown" class="add-to-products-list__dropdown">
        <ul >
          <li>
            <span>Lists</span>
            <i class="icon-times" @click="toggleDropdown" />
          </li>
          <li
            v-for="productsList in productsLists"
            :key="productsList.id"
            @click="addProductToExistingList(productsList)"
          >{{ productsList.name }}</li>
          <li @click="onCreateNewListClick">Create New List (+)</li>
        </ul>
        <loader v-if="isLoading"/>
      </div>
    </div>

    <ModalCreateNewProductsList
      v-if="showCreateNewProductsListModal"
      :showModal="showCreateNewProductsListModal"
      @close="showCreateNewProductsListModal = false"
      @listCreated="addProductToCreatedList"
    />

    <ModalSimple
      v-if="showAddedProductModal"
      :showModal="showAddedProductModal"
      :title="`Item added to ${ activeProductsList ? activeProductsList.name : '' }`"
      :showRejectButton="false"
      confirmButtonText="Close"
      @confirm="closeAddedProductModal"
      @close="closeAddedProductModal"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'

import { extractProductCategory } from '@/transformers'
import LoaderMixin from '@/mixins/LoaderMixin'
import ModalCreateNewProductsList from '@/components/modal/ModalCreateNewProductsList'
import ModalSimple from '@/components/modal/ModalSimple'
import FavoritesIcon from '@/components/FavoritesIcon'

export default {
  components: {
    ModalCreateNewProductsList,
    ModalSimple,
    FavoritesIcon
  },
  props: {
    productData: {
      type: Object,
      required: true
    },
    position: {
      type: Number,
      default: 1
    },
    isFavoritesIconMode: {
      type: Boolean,
      default: false
    },
    analyticsData: {
      type: Object,
      required: true
    }
  },
  mixins: [LoaderMixin],
  computed: {
    ...mapState('user', ['productsLists', 'favoriteProductsCodes']),
    ...mapGetters('user', ['isGuest']),
    isProductInFavorites () {
      return this.favoriteProductsCodes
        ? this.favoriteProductsCodes.includes(this.productData.sku)
        : false
    }
  },
  methods: {
    ...mapActions('user', ['addProductToList', 'setProductsLists']),
    ...mapActions('analytics', ['populateAddProductToListAnalytics']),
    async addProductToExistingList (productsList) {
      this.withLoader(async () => {
        await this.addProductToList({
          listId: productsList.id,
          productCode: this.productData.sku
        })

        this.toggleDropdown(false)
        this.activeProductsList = productsList
        this.showAddedProductModal = true

        this.sendAnalyticsData()
      })
    },
    async addProductToCreatedList (createdList) {
      this.showCreateNewProductsListModal = false
      this.activeProductsList = createdList
      this.showAddedProductModal = true
      this.sendAnalyticsData()
      await this.addProductToList({
        listId: createdList.id,
        productCode: this.productData.sku
      })
    },
    sendAnalyticsData () {
      this.populateAddProductToListAnalytics({
        sku: this.productData.sku,
        catalogId: this.productData.catalogId,
        title: this.productData.title,
        quantity: 1,
        brand: this.productData.brand,
        category: extractProductCategory(this.productData.groupedCategories),
        position: this.position,
        listName: this.analyticsData?.referencePage
      })
    },
    closeAddedProductModal () {
      this.showAddedProductModal = false
      this.activeProductsList = null
    },
    toggleDropdown (val) {
      if (!this.isGuest) this.showListDropdown = typeof val === 'boolean' ? val : !this.showListDropdown
    },
    onCreateNewListClick () {
      this.toggleDropdown(false)
      this.showCreateNewProductsListModal = true
    },
    onLabelClick () {
      const value = this.isFavoritesIconMode && this.isProductInFavorites ? false : !this.showListDropdown
      this.toggleDropdown(value)
    },
    setProductsListsDebounced: _.debounce(function () {
      this.setProductsLists()
    }, 1000)
  },
  created () {
    if (!this.productsLists && !this.isGuest) this.setProductsListsDebounced()
  },
  data () {
    return {
      showCreateNewProductsListModal: false,
      showListDropdown: false,
      activeProductsList: null,
      showAddedProductModal: false
    }
  }
}
</script>
