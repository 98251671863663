var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-to-products-list",class:{
    'add-to-products-list--dropdown-opened': _vm.showListDropdown,
    'add-to-products-list--visible': _vm.isProductInFavorites,
    'add-to-products-list--active': _vm.showCreateNewProductsListModal || _vm.showAddedProductModal
  }},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return _vm.toggleDropdown(false); }),expression:"() => toggleDropdown(false)"}],staticClass:"add-to-products-list__inner"},[_c('h3',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.isGuest ? 'Create an account or log in to save items to a list' : '',
        trigger: 'hover'
      }),expression:"{\n        content: isGuest ? 'Create an account or log in to save items to a list' : '',\n        trigger: 'hover'\n      }"}],staticClass:"text-h4 add-to-products-list__button",on:{"click":_vm.onLabelClick}},[(_vm.isFavoritesIconMode)?_c('FavoritesIcon',{staticClass:"product__favorites",attrs:{"disabled":true,"productCode":_vm.productData.sku}}):_c('span',[_vm._v("Add to List")])],1),(_vm.showListDropdown)?_c('div',{staticClass:"add-to-products-list__dropdown"},[_c('ul',[_c('li',[_c('span',[_vm._v("Lists")]),_c('i',{staticClass:"icon-times",on:{"click":_vm.toggleDropdown}})]),_vm._l((_vm.productsLists),function(productsList){return _c('li',{key:productsList.id,on:{"click":function($event){return _vm.addProductToExistingList(productsList)}}},[_vm._v(_vm._s(productsList.name))])}),_c('li',{on:{"click":_vm.onCreateNewListClick}},[_vm._v("Create New List (+)")])],2),(_vm.isLoading)?_c('loader'):_vm._e()],1):_vm._e()]),(_vm.showCreateNewProductsListModal)?_c('ModalCreateNewProductsList',{attrs:{"showModal":_vm.showCreateNewProductsListModal},on:{"close":function($event){_vm.showCreateNewProductsListModal = false},"listCreated":_vm.addProductToCreatedList}}):_vm._e(),(_vm.showAddedProductModal)?_c('ModalSimple',{attrs:{"showModal":_vm.showAddedProductModal,"title":("Item added to " + (_vm.activeProductsList ? _vm.activeProductsList.name : '')),"showRejectButton":false,"confirmButtonText":"Close"},on:{"confirm":_vm.closeAddedProductModal,"close":_vm.closeAddedProductModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }