<template>
  <div class="products-compare-overlay" v-if="productsToCompare.length">
    <span v-if="!isMobile" class="products-compare-overlay__copy">{{ productsToCompare.length }} out of 10 Products selected to Compare</span>
    <div class="products-compare-overlay__images-carousel">
      <carousel
        :per-page="4"
        :scrollPerPage="false"
        :mouse-drag="false"
        :navigation-enabled="true"
        :pagination-enabled="false"
        ref="compareOverlayCarousel"
      >
        <slide
          v-for="product in productsToCompare"
          :key="product.productData.sku"
        >
          <div
            @click="toggleProductToCompare(product)"
            class="products-compare-overlay__images-item"
          >
            <img
              :src=  "product.productData.image.src"
              :alt="product.productData.image.title"
              loading="lazy"
              class="products-compare-overlay__images-img"
            >
            <i class="icon-times products-compare-overlay__images-icon"></i>
          </div>
        </slide>
      </carousel>
    </div>
    <div class="products-compare-overlay__buttons-wrap">
      <button
        :disabled="productsToCompare.length < 2"
        @click="handleCompareCLick"
        class="button button--primary"
      >Compare</button>
      <button
        @click="setProductsToCompare([])"
        class="button button--underlined products-compare-overlay__cancel"
      >Cancel</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { Carousel, Slide } from 'vue-carousel'

import settings from '@/settings'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'

export default {
  name: 'ProductsCompareOverlay',
  components: {
    Carousel,
    Slide
  },
  mixins: [ScreenWidthMixin],
  watch: {
    productsToCompare (newValue, oldValue) {
      const newProductsAmount = newValue?.length
      const oldProductsAmount = oldValue?.length
      const isAmountIncreased = newProductsAmount - oldProductsAmount > 0

      if (isAmountIncreased && newProductsAmount > 4) {
        this.$nextTick(() => {
          const carouselForwardNavigationButton = this.$refs.compareOverlayCarousel.$el.querySelector('.VueCarousel-navigation-next')
          if (carouselForwardNavigationButton) carouselForwardNavigationButton.click()
        })
      }
      if (!isAmountIncreased && newProductsAmount >= 4) {
        const carouselBackNavigationButton = this.$refs.compareOverlayCarousel.$el.querySelector('.VueCarousel-navigation-prev')
        if (carouselBackNavigationButton) carouselBackNavigationButton.click()
      }
    }
  },
  computed: {
    ...mapState('productsComparison', ['productsToCompare'])
  },
  methods: {
    ...mapMutations('productsComparison', {
      toggleProductToCompare: 'TOGGLE_PRODUCT_TO_COMPARE',
      setProductsToCompare: 'SET_PRODUCTS_TO_COMPARE'
    }),
    handleCompareCLick () {
      const productsSkuString = this.productsToCompare.map(el => el.productData.sku).join(',')
      this.$router.push({
        name: 'ProductsComparison',
        query: { [settings.app.queryTokens.productsToCompare]: productsSkuString }
      })
    }
  }
}
</script>
